var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('p',{staticClass:"mb-1"},[_vm._v("a) What information should always be recorded when using a balance?")]),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3 mb-5",staticStyle:{"width":"300px"},attrs:{"items":_vm.optionsQ1,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question1Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question1Value", $$v)},expression:"inputs.question1Value"}}),_c('p',{staticClass:"mb-1"},[_vm._v(" b) What should be done to the weighing container before adding any chemicals? ")]),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3 mb-5",staticStyle:{"width":"300px"},attrs:{"items":_vm.optionsQ2,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question2Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question2Value", $$v)},expression:"inputs.question2Value"}}),_c('p',{staticClass:"mb-1"},[_vm._v("c) What will cause the mass displayed on a digital scale to fluctuate?")]),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3 mb-5",staticStyle:{"width":"300px"},attrs:{"items":_vm.optionsQ3,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question3Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question3Value", $$v)},expression:"inputs.question3Value"}}),_c('p',{staticClass:"mb-1"},[_vm._v("d) What must be done before leaving the balance area?")]),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"300px"},attrs:{"items":_vm.optionsQ4,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question4Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question4Value", $$v)},expression:"inputs.question4Value"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }