<template>
  <div>
    <v-container>
      <p class="mb-1">a) What information should always be recorded when using a balance?</p>

      <v-select
        v-model="inputs.question1Value"
        :items="optionsQ1"
        class="d-sm-inline-block ml-3 mr-3 mb-5"
        style="width: 300px"
        item-text="text"
        item-value="value"
        label="Select an option:"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>

      <p class="mb-1">
        b) What should be done to the weighing container before adding any chemicals?
      </p>

      <v-select
        v-model="inputs.question2Value"
        :items="optionsQ2"
        class="d-sm-inline-block ml-3 mr-3 mb-5"
        style="width: 300px"
        item-text="text"
        item-value="value"
        label="Select an option:"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>

      <p class="mb-1">c) What will cause the mass displayed on a digital scale to fluctuate?</p>

      <v-select
        v-model="inputs.question3Value"
        :items="optionsQ3"
        class="d-sm-inline-block ml-3 mr-3 mb-5"
        style="width: 300px"
        item-text="text"
        item-value="value"
        label="Select an option:"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>

      <p class="mb-1">d) What must be done before leaving the balance area?</p>

      <v-select
        v-model="inputs.question4Value"
        :items="optionsQ4"
        class="d-sm-inline-block ml-3 mr-3"
        style="width: 300px"
        item-text="text"
        item-value="value"
        label="Select an option:"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>
    </v-container>
  </div>
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';

export default {
  name: 'UCIrvineQuiz1S1Q3',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        question1Value: null,
        question2Value: null,
        question3Value: null,
        question4Value: null,
      },
      optionsQ1: [
        {
          text: 'The number and manufacturer name',
          value: 'answer1',
        },
        {
          text: 'The color',
          value: 'answer2',
        },
        {
          text: 'The type',
          value: 'answer3',
        },
        {
          text: 'The scale',
          value: 'answer4',
        },
      ],
      optionsQ2: [
        {
          text: 'Tare the weighing paper or container',
          value: 'answer1',
        },
        {
          text: 'Zero the scale without anything on the balance pan',
          value: 'answer2',
        },
        {
          text: 'Determine the mass of the weighing paper or container',
          value: 'answer3',
        },
        {
          text: 'Calibrate the digital scale',
          value: 'answer4',
        },
      ],
      optionsQ3: [
        {
          text: 'The chemical being massed is evaporating',
          value: 'answer1',
        },
        {
          text: 'Open draft shields',
          value: 'answer2',
        },
        {
          text: 'Object being massed is hot',
          value: 'answer3',
        },
        {
          text: 'Two of these ',
          value: 'answer4',
        },
        {
          text: 'All of these ',
          value: 'answer5',
        },
      ],
      optionsQ4: [
        {
          text: 'Open the draft shields ',
          value: 'answer1',
        },
        {
          text: 'Clean balance pan with brush & paper towels',
          value: 'answer2',
        },
        {
          text: 'Tare the balance',
          value: 'answer3',
        },
        {
          text: 'Wipe down the balance with a damp paper towel',
          value: 'answer4',
        },
      ],
    };
  },
};
</script>
